.carouselImg {
    height: 500px;
    width: 100%;
}

.servicePageimgClass {
    max-width: 96% !important;
    max-height: 500px;
    margin-top: 2% !important;
}

.galleryHeader {
    font-weight: bold;
    font-size: 40px;
}

.marginClass {
    margin-top: 65px !important;
}

.cardPricingImg {
    width: 40% !important;
    height: 40% !important;
}

.sevicePageBackgound {
    background-color: grey !important;
}

.accordantHeader {
    background-color: #e0e1df !important;
    border: 1px black solid !important;
    font-size: 40px !important;
    font-weight: bold;
}

.accordantHeader .MuiAccordionSummary-content {
    font-size: 20px !important;
}

.servicePageGridItemClass {
    border: 1px solid black !important;
}

.servPriceClass {
    text-align: center;
    color: red !important;
    font-weight: bold !important;
    font-size: 20px !important;
}

.spanCartServ {
    font-weight: bold;
    /* font-style: italic; */
    color: rgb(180, 6, 6);
    font-size: 20px !important;
}

.textAlignLeft{
    text-align: left !important;
    font-weight: bold !important;
    font-size: 120% !important;
    margin-bottom: 2% !important;
    color: rgb(180, 6, 6);
}

.noteClass{
    text-align: left !important;
    font-size: 20px !important;
    margin-top: 1% !important;
    color: rgb(180, 6, 6);
    font-style: italic;
}