.carouselImg {
    height: 500px;
    width: 100%;
}

.galleryHeader {
    font-weight: bold;
    font-size: 40px;
    background-color: #e1e0dc !important;
    box-shadow: none !important;
}



.marginClass {
    margin-top: 65px !important;
}

.cardHeader {
    font-size: 30px !important;
    font-weight: bold !important;
    color: black !important;
    font-family: 'Oswald Variable', sans-serif !important;
    /* font-family: 'Playfair Display Variable', serif !important; */
    /* font-family: 'Acme' !important; */
}

.descriptionClass {
    font-size: 20px !important;
}

.aboutDescMainClass {
    font-size: 25px !important;
    /* font-family: 'Oswald Variable', sans-serif !important; */
    /* font-family: 'Playfair Display Variable', serif !important; */
    /* font-family: 'Acme' !important; */
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}


.abountImgClass {
    max-width: 96% !important;
    max-height: 500px;
    margin-top: 2% !important;
}

.aboutStartingImg {
    height: 90%;
    width: 50%;
}

.abountCardClass {
    /* border: 1px solid black !important; */
    /* background-image: linear-gradient(120deg, #e4e2de 0%, #d8dad7 100%); */
    background-color: #e1e0dc !important;
    box-shadow: none !important;
}

.aboutPaperImgClass {
    /* background-image: linear-gradient(120deg, #e4e2de 0%, #d8dad7 100%) !important; */
    background-color: #e1e0dc !important;
}

.abountCardClass .MuiPaper-root {
    background-color: #e1e0dc !important;
    box-shadow: none !important;
}

.abountCardClass .css-pwngrv-MuiPaper-root-MuiCard-root {
    background-color: #e1e0dc !important;
    box-shadow: none !important;
}