.App {
  height: 100% !important;
  width: auto !important;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  /* height: 100vh !important; */
  height: 100% !important;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.fullWidth {
  width: 100% !important;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar {
  border-radius: 6px;
  width: 6px;
  height: 6px;
  background-color: transparent !important;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #999;
}

.btnClass {
  font-size: 12px !important;
  text-transform: capitalize !important;
  padding: 6px 16px !important;
}

.MuiPickerStaticWrapper-content {
  min-width: auto;
  width: auto;
  border-radius: 10px;
}

.PrivatePickersSlideTransition-root {
  min-height: 160px !important;
  min-width: 220px !important;
}

.MuiDateRangePickerDay-dayOutsideRangeInterval,
.MuiDateRangePickerDay-day,
.MuiDateRangePickerDay-day .Mui-selected {
  height: 25px !important;
  width: 25px !important;
}

.MuiDayPicker-weekDayLabel,
.MuiPickersDay-root {
  font-size: 12px !important;
  height: 27px !important;
  width: 27px !important;
}

.MuiPickersArrowSwitcher-root {
  padding: 8px 8px 8px 8px !important;
}

.MuiCalendarPicker-root,
.css-epd502 {
  min-height: auto !important;
  width: 250px !important;
}

.MuiDayPicker-monthContainer {
  padding-bottom: 10px !important;
}

.MuiYearPicker-root {
  max-height: 200px !important;
}

.PrivatePickersYear-yearButton {
  height: 30px !important;
  margin: 0 !important;
}

.MuiDataGrid-root {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.messagePopupClass .MuiDialogTitle-root {
  padding: 3px 16px !important;
  background-color: #1976d28a;
}

.messagePopupClass .MuiDialogContent-root {
  padding: 8px 24px !important;
}

.messagePopupClass .MuiDialogTitle-root p {
  font-size: 16px !important;
  font-weight: bold !important;
}

.messagePopupClass .MuiSvgIcon-root {
  font-size: 1.9rem !important;
}

.messagePopupClass p {
  font-size: 14px !important;
}

.MuiDialogActions-root {
  padding: 8px 24px !important;
}

.crossIcon .MuiIconButton-root {
  padding: 0 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiSelect-select {
  text-align: left !important;
}

.MuiMenuItem-root {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.MuiDataGrid-columnHeaders {
  background-color: #1976d252 !important;
}

.MuiDataGrid-cell--editing .MuiOutlinedInput-notchedOutline {
  border-width: 0 !important;
}

.MuiDataGrid-cell--editing input {
  color: blue !important;
  text-align: left !important;
  padding: 0px 9px !important;
  font-size: 12px !important;
}

.MuiButton-startIcon .MuiSvgIcon-root,
.MuiButton-endIcon .MuiSvgIcon-root {
  font-size: 16px !important;
}

.navigationBarClass .MuiAppBar-root {
  background-color: #666e68 !important;
  /* align-items: end !important; */
}

.alignItemLeft {
  align-items: left !important;
}

.alignItemRight {
  align-items: end !important;
}

.alignItemLeftJustify {
  text-align-last: left;
  /* font-family: 'Oswald Variable', sans-serif !important; */
  /* font-family: 'Playfair Display Variable', serif !important; */
  font-family: 'Acme' !important;
  font-size: 2vw !important;
}

.linkButtonsClass{
  font-family: 'Oswald Variable', sans-serif !important;
  /* font-family: 'Playfair Display Variable', serif !important; */
  /* font-family: 'Acme' !important; */
  font-size: 1vw !important;
}

/* .toolbar{
  display: flex !important;
} */

.openDrawerClass {
  height: auto !important;
  background-color: #fdfbfb !important;
  font-family: 'Oswald Variable', sans-serif !important;
}

.mobileHeaderFont {
  /* font-size: 12px !important; */
  word-break: break-all !important;
  width: 150px !important;
  text-align: center !important;
  font-family: 'Acme' !important;
}

.mobileDraweerClass .MuiDrawer-paper{
  height: auto !important;
  
}

.listItemClass .MuiTypography-root{
  font-family: 'Oswald Variable', sans-serif !important;
}

.tileClassButton {
  color: white !important;
}

@media only screen and (max-width: 600px) {
  .navigationBarClass .MuiAppBar-root {
    background-color: #666e68 !important;
    align-items: end !important;
  }
}