.carouselImg {
    height: 500px;
    width: 100%;
}

.galleryHeader {
    font-weight: bold;
    font-size: 40px;
}

.marginClass {
    margin-top: 65px !important;
}

.bgImgClass {
    /* height: 100% !important; */
    height: 92.7vh !important;
    width: 100% !important;
    background-size: cover !important;
}

.contactGrid {
    background-image: linear-gradient(120deg, #e4e2de 0%, #d8dad7 100%);
}

.iconSizeClass {
    height: 100% !important;
    width: 30% !important;
    opacity: 0.2;
}

.contactNameClass {
    height: 100% !important;
    width: 100% !important;
    font-size: 5vw !important;
    /* margin-bottom: 10% !important; */
}

.contDesc {
    font-size: 150% !important;
    background-image: linear-gradient(120deg, #e4e2de 0%, #d8dad7 100%);
}

.contCardTitleClass {
    font-size: 5vh !important;
    font-weight: bold !important;
}

.colorWhiteClass {
    color: black !important;
}

.h1ClassContactus{
    margin: 0px !important;
    /* font-family: "Acme" !important; */
}

.contactTextSize{
    font-size: 2.9vh !important;
    margin-top: 3% !important;
    margin-bottom: 3% !important;
    font-style: italic;
    color: #a81824 !important;
}

.backgroundColorClass{
    background-image: linear-gradient(120deg, #e4e2de 0%, #d8dad7 100%);
}

.gridDescriptionClass{
    font-size: 2.5vh !important;
}

.gridTitleClass{
    font-size: 5vh !important;
    font-weight: bold !important;
}