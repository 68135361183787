.carouselImg {
    height: 600px !important;
    width: 80% !important;
    /* max-height: 50% !important; */
}

.homeCardTitleClass {
    font-size: 26px !important;
    font-weight: bold !important;
    font-family: 'Oswald Variable', sans-serif !important;
}

.cardDescriptionClass {
    font-size: 16px !important;
    /* font-family: 'Oswald Variable', sans-serif !important; */
    /* font-family: 'Acme' !important; */
    color: black;
    
}

.cardButton {
    align-items: center;
    color: black !important;
    background-color: #c9cdca !important;
}

.cardButtonActionClass {
    align-items: center !important;
    align-content: center !important;
    display: block !important;

}

.carouselMaxWidth .MuiPaper-root {
    /* max-width: 90% !important;
    margin-left: 5% !important; */
    /* background-color: #c9cdca !important; */
    background-image: linear-gradient(120deg, #e4e2de 0%, #d8dad7 100%) !important;
    border-radius: 0px !important;
    box-shadow: none !important;
}

.pageBackgroundColor {
    /* --c: rgb(34 133 251 / 3%);
    --t: transparent;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-image: -webkit-repeating-linear-gradient(45deg, var(--c) 0, var(--c) 20px, var(--t) 22px, var(--t) 32px, var(--c) 32px, var(--c) 44px, var(--t) 44px, var(--t) 56px, var(--c) 56px, var(--c) 68px, var(--t) 68px, var(--t) 80px, var(--c) 0), repeating-linear-gradient(-45deg, var(--c) 0, var(--c) 20px, var(--t) 20px, var(--t) 32px, var(--c) 32px, var(--c) 44px, var(--t) 44px, var(--t) 68px, var(--c) 56px, var(--c) 68px, var(--t) 68px, var(--t) 80px, var(--c) 0), linear-gradient(to bottom right, #78fc353d, #8fefef2b);
    background-image: -moz-repeating-linear-gradient(45deg, var(--c) 0, var(--c) 20px, var(--t) 22px, var(--t) 32px, var(--c) 32px, var(--c) 44px, var(--t) 44px, var(--t) 56px, var(--c) 56px, var(--c) 68px, var(--t) 68px, var(--t) 80px, var(--c) 0), repeating-linear-gradient(-45deg, var(--c) 0, var(--c) 20px, var(--t) 20px, var(--t) 32px, var(--c) 32px, var(--c) 44px, var(--t) 44px, var(--t) 68px, var(--c) 56px, var(--c) 68px, var(--t) 68px, var(--t) 80px, var(--c) 0), linear-gradient(to bottom right, #78fc353d, #8fefef2b);
    background-image: -o-repeating-linear-gradient(45deg, var(--c) 0, var(--c) 20px, var(--t) 22px, var(--t) 32px, var(--c) 32px, var(--c) 44px, var(--t) 44px, var(--t) 56px, var(--c) 56px, var(--c) 68px, var(--t) 68px, var(--t) 80px, var(--c) 0), repeating-linear-gradient(-45deg, var(--c) 0, var(--c) 20px, var(--t) 20px, var(--t) 32px, var(--c) 32px, var(--c) 44px, var(--t) 44px, var(--t) 68px, var(--c) 56px, var(--c) 68px, var(--t) 68px, var(--t) 80px, var(--c) 0), linear-gradient(to bottom right, #78fc353d, #8fefef2b);
    background-image: repeating-linear-gradient(45deg, var(--c) 0, var(--c) 20px, var(--t) 22px, var(--t) 32px, var(--c) 32px, var(--c) 44px, var(--t) 44px, var(--t) 56px, var(--c) 56px, var(--c) 68px, var(--t) 68px, var(--t) 80px, var(--c) 0), repeating-linear-gradient(-45deg, var(--c) 0, var(--c) 20px, var(--t) 20px, var(--t) 32px, var(--c) 32px, var(--c) 44px, var(--t) 44px, var(--t) 68px, var(--c) 56px, var(--c) 68px, var(--t) 68px, var(--t) 80px, var(--c) 0), linear-gradient(to bottom right, #78fc353d, #8fefef2b); */
    background-image: linear-gradient(120deg, #e4e2de 0%, #d8dad7 100%);
    /* padding-left: 5px;
    padding-right: 5px; */
}

.cardContainerClass {
    background-image: linear-gradient(120deg, #e4e2de 0%, #d8dad7 100%) !important;
    padding: 1% !important;
}

.homePageHeightClass {
    height: 100vh !important;
}