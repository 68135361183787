.carouselImg {
    height: 500px;
    width: 100%;
}

.galleryHeader {
    font-weight: bold;
    font-size: 40px;
}

.marginClass {
    margin-top: 0px !important;
}

.galleryImgClass {
    max-width: 96% !important;
    max-height: 500px;
    margin-top: 2% !important;
}

.legendClass {
    font-size: 250%;
    font-weight: bold;
    font-family: 'Oswald Variable', sans-serif !important;
}

.imgPaperClass {
    border: 1px solid black !important;
}

/* .carvingGridContainerClass {
    background-image: linear-gradient(120deg, #e4e2de 0%, #d8dad7 100%) !important;
} */
